import React, { FC, useEffect, useState } from 'react';
import { Badge, BadgeProps } from '@mui/material';
import { useQuery } from '@apollo/client';
import { useConfig } from '../env';
import { useAuth } from 'oidc-react';
import { MY_UNREAD_TASKS } from './task.graphql';

export const UnreadTasksBadge: FC<BadgeProps> = (props) => {
  const auth = useAuth();
  const profile = auth.userData?.profile;
  const config = useConfig();
  const [lastRead, setLastRead] = useState<Date>();
  const pollIntervall = config ? config.defaultPollInterval : 0;
  const { data } = useQuery(MY_UNREAD_TASKS, { pollInterval: pollIntervall, variables: { ownerId: profile?.sub, lastRead } });

  useEffect(() => {
    const lastRead = localStorage.getItem('lastRead');
    if (lastRead === null) setLastRead(new Date());
    else setLastRead(new Date(lastRead));
  }, []);

  return <Badge {...props} badgeContent={data?.tasks?.length} color="error" />;
};
