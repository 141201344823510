import styled from '@emotion/styled';
import React, { FC } from 'react';
import { ExpandMore, Functions } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import katex from 'katex';

export const LatexHelp: FC = () => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />} id="panel-header" aria-controls="panel-content">
        <span
          dangerouslySetInnerHTML={{
            __html: katex.renderToString('\\LaTeX'),
          }}></span>
        -formler
      </AccordionSummary>
      <AccordionDetails>
        <p style={{ marginTop: 0 }}>
          Formler kan skrives på LaTeX-format. Se dokumentasjonen til{' '}
          <a href="https://katex.org/docs/supported" target="_blank">
            <span
              dangerouslySetInnerHTML={{
                __html: katex.renderToString('\\KaTeX'),
              }}></span>
          </a>{' '}
          for en full oversikt over hvilke funksjoner som støttes. .
        </p>
        <p>
          For å skrive formler i LaTeX-format kan man trykke på <Functions style={{ verticalAlign: 'text-bottom' }} />
          -ikonet i verktøylinjen og skrive formelen mellom $-tegnene.
        </p>
        <p>
          For hjelp til å skrive mer avanserte formler, gå til denne{' '}
          <a href="https://latexeditor.lagrida.com/" target="_blank">
            online LaTeX editoren
          </a>
          .
        </p>
        <SectionHeader>Enkel formel</SectionHeader>
        <StyledFormula marginRight>$E=mc^2$</StyledFormula>
        <BlockKatex dangerouslySetInnerHTML={{ __html: katex.renderToString('E=mc^2') }}></BlockKatex>
        <SectionHeader>Fonter</SectionHeader>
        <ExampleHeader>Formel i Sans Serif</ExampleHeader>
        <StyledFormula marginRight>{`$\\mathsf{E = mc^2}$`}</StyledFormula>
        <BlockKatex dangerouslySetInnerHTML={{ __html: katex.renderToString('\\mathsf{E=mc^2}') }}></BlockKatex>
        <ExampleHeader>Formel i kursiv Sans Serif</ExampleHeader>
        <StyledFormula marginRight>{`$\\mathsfit{E = mc^2}$`}</StyledFormula>
        <BlockKatex dangerouslySetInnerHTML={{ __html: katex.renderToString('\\mathsfit{E=mc^2}') }}></BlockKatex>
        <ExampleHeader>Tekst i Sans Serif</ExampleHeader>
        <StyledFormula marginRight>{`$\\textsf{Dette er en tekst}$`}</StyledFormula>
        <BlockKatex dangerouslySetInnerHTML={{ __html: katex.renderToString('\\textsf{Dette er en tekst}') }}></BlockKatex>
        <ExampleHeader>Tekst i kursiv Sans Serif</ExampleHeader>
        <StyledFormula marginRight>{`$\\textsf{\\textit{Dette er en tekst}}$`}</StyledFormula>
        <BlockKatex dangerouslySetInnerHTML={{ __html: katex.renderToString('\\textsf{\\textit{Dette er en tekst}}') }}></BlockKatex>
        <SectionHeader>Display style</SectionHeader>
        Når en formel blir høy, vil LaTeX ofte vise veldig små fonter. Dette kan man unngå ved å legge inn{' '}
        <StyledFormula>\displaystyle</StyledFormula> i formelen.
        <ExampleHeader>Eksempel 1 uten display style</ExampleHeader>
        <StyledFormula marginRight>{`$\\mathsfit{\\frac{x^2}{y}}$`}</StyledFormula>
        <BlockKatex dangerouslySetInnerHTML={{ __html: katex.renderToString('\\mathsfit{\\frac{x^2}{y}}') }}></BlockKatex>
        <ExampleHeader>Eksempel 2 med display style</ExampleHeader>
        <StyledFormula marginRight>{`$\\displaystyle \\mathsfit{\\frac{x^2}{y}}$`}</StyledFormula>
        <BlockKatex dangerouslySetInnerHTML={{ __html: katex.renderToString('\\displaystyle \\mathsfit{\\frac{x^2}{y}}') }}></BlockKatex>
        <ExampleHeader>Eksempel 3 uten display style</ExampleHeader>
        <StyledFormula marginRight>{`$\\mathsfit{\\frac{\\sum_{i=1}^{n} p_i^2}{n \\cdot p_0^2}}$`}</StyledFormula>
        <BlockKatex
          dangerouslySetInnerHTML={{
            __html: katex.renderToString('\\mathsfit{\\frac{\\sum_{i=1}^{n} p_i^2}{n \\cdot p_0^2}}'),
          }}></BlockKatex>
        <ExampleHeader>Eksempel 4 med display style på formelen og på summen</ExampleHeader>I mer kompliserte formler må man legge på{' '}
        <StyledFormula>\displaystyle</StyledFormula> flere steder for å oppnå ønsket uttrykk. Da må man også legge på{' '}
        <StyledFormula>\mathsfit</StyledFormula> på nytt for å få riktig stilsetting:
        <br />
        <StyledFormula>{`$\\displaystyle \\mathsfit{\\frac{\\displaystyle \\mathsfit{\\sum_{i=1}^{n} p_i^2}}{n \\cdot p_0^2}}$`}</StyledFormula>
        <br />
        <BlockKatex
          dangerouslySetInnerHTML={{
            __html: katex.renderToString('\\displaystyle \\mathsfit{\\frac{\\displaystyle \\mathsfit{\\sum_{i=1}^{n} p_i^2}}{n \\cdot p_0^2}}'),
          }}></BlockKatex>
        <SectionHeader>Enheter</SectionHeader>
        For å skrive enheter, f.eks. (m/s) etter en formel, kan man bruke <StyledFormula>\textsf</StyledFormula> for å skrive tekst og{' '}
        <StyledFormula>\quad</StyledFormula> eller <StyledFormula>\qquad</StyledFormula> for å lage et mellomrom.
        <ExampleHeader>Eksempel 1</ExampleHeader>
        <StyledFormula>{`$\\displaystyle \\mathsfit{C_L = \\sqrt{\\frac{E}{\\rho}} \\qquad \\textsf{(m/s)}}$`}</StyledFormula>
        <BlockKatex
          dangerouslySetInnerHTML={{
            __html: katex.renderToString('\\displaystyle \\mathsfit{C_L = \\sqrt{\\frac{E}{\\rho}} \\qquad \\textsf{(m/s)}}'),
          }}></BlockKatex>
        <ExampleHeader>Eksempel 2</ExampleHeader>
        <StyledFormula>{`$\\displaystyle \\mathsfit{C_L = \\sqrt{\\frac{E}{\\rho}}} \\quad \\textsf{(m/s)}$`}</StyledFormula>
        <BlockKatex
          dangerouslySetInnerHTML={{
            __html: katex.renderToString('\\displaystyle \\mathsfit{C_L = \\sqrt{\\frac{E}{\\rho}}} \\quad \\textsf{(m/s)}'),
          }}></BlockKatex>
      </AccordionDetails>
    </Accordion>
  );
};

const BlockKatex = styled.div`
  margin-top: 10px;
`;

const SectionHeader: FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Typography variant="h6" component="h3" marginTop={2}>
      {children}
    </Typography>
  );
};

const ExampleHeader = styled.div`
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 6px;
`;

const StyledFormula = styled.span<{ marginRight?: boolean }>`
  background: #e6edf0;
  color: #000;
  font-family: monospace;
  font-size: 0.8rem;
  padding: 0.2rem;
  ${({ marginRight }) => marginRight && 'margin-right: 10px;'}
`;
