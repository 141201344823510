import React, { FC } from 'react';
import { Editor } from '@tiptap/react';
import { Functions } from '@mui/icons-material';
import { MenuButton, useRichTextEditorContext } from 'mui-tiptap';

export const FormulaToolbarButton: FC = () => {
  const editor = useRichTextEditorContext();
  const handleOnClick = async () => {
    addFormula(editor);
  };
  if (!editor) return null;
  return (
    <>
      <MenuButton disabled={!editor?.isEditable} IconComponent={Functions} tooltipLabel="LaTeX-formel" onClick={handleOnClick}></MenuButton>
    </>
  );
};

const addFormula = (editor: Editor | null) => {
  editor
    ?.chain()
    .focus()
    .insertContent('$\\mathsfit{}$')
    .setTextSelection(editor.state.selection.from + 11) // Move cursor inside the formula
    .run();
};
